.nav {
  height: 100%;
  width: 100%;
  @include flex(column, space-between, center);
  flex-direction: column;
  gap: 1rem;
  user-select: none;
  padding-bottom: 0.8rem;

  .nav-content {
    width: 90%;
    @include flex(column, space-between, flex-start);
    flex-direction: column;
    gap: 1rem;
    @media (max-width: 1150px) {
      align-items: center;
    }

    .nav-logo {
      border-radius: 16px;
      @include flex(column, flex-start, null);
      padding: 0 4px;
      a {
        @include flex(column, flex-start, null);
        border-radius: 50%;
        padding: 16px;
        &:hover {
          background-color: $color3-2;
        }
        img {
          width: 30px;
        }
      }
    }

    .nav__list {
      @include flex(column, space-between, flex-start);
      flex-direction: column;
      gap: 0.8rem;
      padding: 0 4px;
      .nav-link {
        padding: 8px 16px;
        border-radius: 24px;
        @include flex(row, flex-start, center);
        gap: 1rem;
        font-size: 18px;
        cursor: pointer;
        transition: all 0.2s ease-out;
        position: relative;
        &:hover {
          background-color: $color3-2;
        }
        span.active {
          font-weight: bold;
        }
        svg {
          width: 28px;
        }
        svg.explore {
          display: none;
        }
        .info-notif {
          height: 18px;
          min-width: 18px;
          font-size: 11px;
          font-weight: 500;
          border-radius: 24px;
          background-color: $color3;
          @extend .flex-center;
          @include position-abs(2px, null, null, 26px, null, null);
        }
        @media (max-width: 1150px) {
          span {
            display: none;
          }
          // svg.explore {
          //   display: block;
          // }
          // svg.htag {
          //   display: none;
          // }
        }
        @media (max-width: 950px) {
          svg.explore {
            display: block;
          }
          svg.htag {
            display: none;
          }
        }
      }
    }

    .add-tweet-nav {
      width: 100%;
      background-color: $color3;
      @extend .flex-center;
      gap: 1rem;
      padding: 14px 16px;
      cursor: pointer;
      border-radius: 999px;
      user-select: none;
      font-size: 18px;
      font-weight: bold;
      transition: all 0.1s ease-out;
      &:active {
        transform: scale(0.99);
      }
      svg {
        display: none;
        width: 28px;
      }
      @media (max-width: 1150px) {
        width: 55px;
        height: 55px;
        border-radius: 50%;
        svg {
          display: block;
        }
        span {
          display: none;
        }
      }
    }
  }

  .nav-user-container {
    width: 95%;
    .nav-user {
      border-radius: 36px;
      transition: all 0.2s ease-in-out;
      user-select: none;
      cursor: pointer;
      &:hover {
        background-color: $color6;
      }

      @media (max-width: 1150px) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        .username,
        svg {
          display: none;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
