.TooltipCardUser {
  .content {
    min-width: 280px;
    max-width: 280px;
    padding: 0.8rem;
    background-color: $color1;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba($color5, 0.5);
  }
}
