*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

body,
html {
  background: $color1;
  color: $color2;
  font-family: $font1;
  font-weight: 350;
  width: 100%;
  height: 100%;
  @include srolled
}

body {
  overflow-y: scroll;
  @include srolled
}

#root {
  width: 100%;
  height: 100%;
  @media (max-width: 500px) {
    @include flex(column, space-between, flex-start)
  }
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: $color2;
}

a:visited {
  color: $color2;
}

input,
button {
  font-family: $font1;
  color: $color2;
  font-size: 16px;
  border: none;
  outline: none;
}

.spinners-container {
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999999;
  background-color: $color1;
  @extend .flex-center;

  @media (max-width: 500px) {
    // margin-top: 150px;
  }

  img {
    width: 60px;
    height: 60px;
  }
}

.spinners-container.displayNode {
  left: -200%;
  top: -200%;
  display: none;
}