$font1: TwitterChirp, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
// $font2: ui-sans-serif, system-ui, sans-serif;

$color1: #15202b;
$color2: #ebeeee;
$color3: #1d9bf0;
$color3-2: #1d9cf010;
$color4: #1e2732;
$color5: #919090;
$color6: #273340;
$color7: #75757562;
$color8: #f91880;
$color9: #2732408b;
$color10: #31404e;
$red: #F4212E;
$orange: orange;
$green: #23ad5c;
$green: #aeaeae;