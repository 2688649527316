.btnFollow {
  width: 100px;
  z-index: 2;
  span.vous {
    font-weight: 600;
    // margin-left: 0.5rem;
  }
  button {
    background-color: $color2;
    font-weight: 700;
    width: 90px;
    padding: 5px;
    transition: all 0.1s ease-in-out;
    span {
      color: $color1;
      transition: all 0.1s ease-in-out;
    }
  }
}
.btnFollow.transparent {
  button {
    background-color: transparent;
    &:hover {
      background-color: rgba($red, 0.1);
      border-color: rgba($red, 0.4);
      span {
        color: $red;
      }
    }
    span {
      color: $color2;
    }
  }
}