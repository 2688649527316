.lists-page {
  .FavoriteSection {
    width: 100%;
    padding: 1rem;
    border-bottom: 1px solid $color7;
    .favorite-empty-box {
      margin: 2rem 0.5rem 1rem 1rem;
      p {
        color: $color5;
      }
    }
  }
  .ListSection {
    border-bottom: none;
  }
}
