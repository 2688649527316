.PopupPostOrCommentOptionCard {
  width: 300px;
  @include flex(column, center, flex-start);
  gap: 0.3rem;
  background-color: $color1;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba($color5, 0.5);
  
  .items {
    width: 100%;
    padding: 0.9rem 0 0.9rem 1rem;
    font-size: 0.9em;
    @include flex(row, flex-start, center);
    gap: 0.6rem;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    &:hover {
      background-color: $color9;
    }
    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    &:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    
    svg {
      width: 20px !important;
    }
  }
  .items.delete {
    span {
      color: $red;
    }
  }
}
