.Bookmarks {
  .not-book {
    margin-top: 80px;
    width: 100%;
    @extend .flex-center;
    flex-direction: column;
    gap: 1rem;

    img {
      width: 350px;
    }

    .text {
      h2 {
        font-size: 32px;
        font-weight: 800;
        margin-bottom: 5px;
      }

      p {
        color: $color5;
      }
    }
  }
}
