.Error404 {
  width: 100%;
  height: 100vh;
  background: linear-gradient(45deg, #8500ff, #5acaff);

  #container-Error404 {
    @include position-abs(10%, 10%, 10%, 10%, null, null);
    border-radius: 10px;
    @extend .flex-center;
    background: url("./../../../assets/img/p404.png") #151729;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.5);
    padding: 30px;

    @media screen and (max-width: 460px) {
      width: 100%;
      height: 100vh;
      @include position-abs(0, 0, 0, 0, null, null);
    }

    .content {
      text-align: center;

      @media screen and (max-width: 460px) {
        @include flex(column, center, center);
        gap: 1.5rem;
      }

      h2 {
        font-size: 18vw;
        color: $color2;
        line-height: 1em;
        margin-bottom: 1.5rem;
      }
      h4 {
        font-size: 1.5em;
        color: $color1;
        background: $color2;
        margin-bottom: 20px;
        position: relative;
        font-weight: 300;
        padding: 10px 20px;
        display: inline-block;
        border-radius: 8px;
      }
      p {
        font-size: 1.2em;
      }
      span {
        position: relative;
        display: inline-block;
        padding: 10px 25px;
        background: #ff0562;
        margin-top: 25px;
        border-radius: 25px;
      }
    }
  }
}
