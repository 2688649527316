.NavTabs {
  @include flex(row, space-around, null);

  .box-btn {
    @extend .flex-center;
    // width: 100%;
    cursor: pointer;
    flex-grow: 1;
    &:hover {
      background-color: $color9;
    }

    .tabs {
      padding: 16px 4px;
      text-align: center;
      background: transparent;
      cursor: pointer;
      position: relative;
      outline: none;
      color: $color5;
      font-size: 16px;
      transition: font-weight 0.1s ease-in-out;
    }

    .active-tabs {
      color: $color2;
      font-weight: 600;
      // font-size: 16px;
    }
  
    .active-tabs::before {
      content: "";
      display: block;
      position: absolute;
      bottom: -1px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 4px;
      border-radius: 15px;
      background: $color3;
    }
  }

  
}
