.navLeft-container {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: rgba($color10, 0.5);
  top: 0px;
  left: 0px;
  transform: translateX(-100%);
  transition: transform 0.1s ease-in-out;
  @media (max-width: 500px) {
    display: block;
  }
}
.closed {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 4;
  background-color: rgba($color10, 0.5);
}

.nav-mobile-left {
  width: 80%;
  height: 100%;
  padding: 1rem 0.3rem;
  background-color: $color1;
  @include flex(column, flex-start, flex-start);
  gap: 1.5rem;
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translateX(-100%);
  z-index: 5;
  transition: transform 0.3s ease-in-out;

  .nav-header {
    padding: 0.5rem 1.3rem;
    width: 100%;
    @include flex(row, space-between, center);
    h4 {
      font-size: 1.1em;
    }
    .close-btn {
      width: 35px;
      border-radius: 50%;
      padding: 5px;
      cursor: pointer;
      @extend .flex-center;
      &:hover {
        background-color: $color9;
      }
      img {
        width: 100%;
      }
    }
  }

  .nav-card-user {
    width: 100%;
    padding: 0.5rem 1.3rem;
    .plus {
      width: 30px;
      height: 30px;
      padding: 4px;
      border-radius: 50%;
      border: 1px solid $color5;
      cursor: pointer;
      svg {
        width: 100%;
      }
    }
  }

  .nav-items {
    width: 90%;
    @include flex(column, flex-start, flex-start);
    gap: 1.2rem;
    .nav-link {
      padding: 8px 16px;
      border-radius: 24px;
      @include flex(row, flex-start, center);
      gap: 1.5rem;
      font-size: 18px;
      cursor: pointer;
      transition: all 0.2s ease-out;
      &:hover {
        background-color: $color3-2;
      }
      span {
        font-weight: bold;
      }
      svg {
        width: 28px;
      }
    }
  }
}

.nav-mobile-left.displayNav {
  transform: translateX(0);
}
