.container-input-group {
  width: 100%;
  max-width: 460px;
}
.input-group {
  width: 100%;
  // max-width: 460px;
  position: relative;
  border: 1px solid $color5;
  border-radius: 8px;
  // margin: 32px;
}
.input-group .InputCustom {
  padding: 16px 30px;
  border: none;
  background-color: transparent;
  width: 100%;
  color: var(--text);
  font-size: 16px;
  line-height: 24px;
}

// .input-group:hover,
.input-group:focus-within {
  border-color: $color3;
}

.input-group .InputCustom:focus,
.input-group:hover .InputCustom {
  color: $color3;
}

.input-group label {
  position: absolute;
  background-color: transparent;
  padding: 16px 24px;
  line-height: 24px;
  top: 0;
  left: 0;
  bottom: 0;
  color: $color5;
  cursor: text;
  transition: 0.4s ease;
}

.input-group {
  &:focus-within ~ label,
  .InputCustom:focus ~ label,
  .InputCustom:valid ~ label {
    padding: 0px 8px;
    background-color: $color1;
    top: -12px;
    left: 16px;
    bottom: auto;
    color: $color3;
  }
}

span.maxLength {
  @include position-abs(4px, 10px, null, null, null, null);
  font-size: 11px;
  color: $color5;
}

div.showValue {
  @include position-abs(29%, 10px, null, null, null, null);
  width: 22px;
  @extend .flex-center;
  cursor: pointer;
  img {
    width: 22px;
  }
}

.input-error {
  margin-left: 1.5rem;
  margin-top: 5px;
  color: $red;
}

.container-input-group .indicator {
  height: 10px;
  margin: 10px 5px;
  @include flex(row, space-between, center);
  display: none;
  gap: 0.2rem;
}
.container-input-group .indicator span {
  position: relative;
  height: 100%;
  width: 100%;
  background: lightgrey;
  border-radius: 5px;
}
.container-input-group .indicator span.active:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 5px;
}
.indicator span.weak:before {
  background-color: $red;
}
.indicator span.medium:before {
  background-color: $orange;
}
.indicator span.strong:before {
  background-color: $green;
}
