.modalAuth {
  overflow: hidden;
}

.modal-auth-global {
  background-color: rgba($color10, 0.5);

  .spinners-container {
    z-index: 99999;
  }

  .modal-container {
    gap: 0;
    // padding: 0;
    height: auto;
    @media (max-width: 600px) {
      height: 100%;
    }
    .modal-header {
      width: 100%;
      @include flex(row, flex-start, flex-start);
      gap: 1.5rem;
      .img {
        width: 40px;
        border-radius: 50%;
        padding: 5px;
        cursor: pointer;
        @extend .flex-center;
        &:hover {
          background-color: $color9;
        }
        img {
          width: 100%;
        }
      }
      h1 {
        font-size: 1.7em;
      }
    }

    .modal-content {
      margin-top: 1rem;
      @include flex(row, center, center);
      width: 100%;
      height: 100%;

      .container-login {
        width: 100%;
        @include flex(column, center, center);
        gap: 1.8rem;
        @media (max-width: 600px) {
          padding: 0 1rem;
        }

        .box-social-auth {
          width: 100%;
          max-width: 460px;
          @include flex(column, center, center);
          gap: 0.8rem;
          .btn-signup-ext {
            font-size: 0.9em;
            font-weight: 500;
            padding-top: 8px;
            padding-bottom: 8px;
            background-color: $color2;
            color: $color1;
            border: 1px solid $color2;
            img {
              width: 18px;
            }
          }
          .btn-signup-ext[disabled] {
            background-color: rgba($color2, 0.7) !important;
            border-color: transparent;
            color: $color1;
            cursor: not-allowed;
          }
        }

        .sep {
          @include flex(column, center, null);
          width: 100%;
          max-width: 460px;
          height: 10px;
          position: relative;
          hr {
            background-color: $color5;
            height: 1px;
            width: 100%;
            max-width: 460px;
            border: none;
            outline: none;
          }
          span {
            @include position-abs(50%, 0, 0, 50%, -50%, -50%);
            @extend .flex-center;
            width: 35px;
            background-color: $color1;
            color: $color5;
          }
        }
      }

      form {
        @include flex(column, center, center);
        gap: 1rem;
        width: 100%;

        p {
          text-align: center;
          @media (min-width: 600px) {
            padding: 8px;
          }
        }

        .btn-signup {
          width: 100%;
          max-width: 460px;
          margin-top: 0.5rem;
          padding: 8px 16px;
          border-radius: 30px;
          background-color: $color2;
          box-shadow: 0 0 8px $color4;
          span {
            font-weight: 500;
            font-size: 1em;
            color: $color1;
          }
        }
        .btn-signup[disabled] {
          background-color: rgba($color2, 0.7) !important;
          color: $color1;
          cursor: not-allowed;
        }

        .info {
          margin-top: 0.5rem;
          @include flex(column, center, center);
          gap: 0.5rem;
          a {
            color: $color3;
          }
        }

        .error-auth {
          width: 100%;
          max-width: 460px;
          padding: 0.5rem 1rem;
          border: 1px solid $red;
          border-radius: 8px;
          color: $red;
          @include flex(row, center, center);
          gap: 0.8rem;
        }
      }

      .info-auth {
        padding: 0 1em;
        @extend .flex-center;
        flex-direction: column;
        gap: 0.5rem;

        img {
          width: 120px;
        }

        P {
          font-size: 1.1em;
          line-height: 1.4em;
        }

        .btn-login {
          width: 100%;
          max-width: 460px;
          padding: 8px 16px;
          background-color: $color2;
          color: $color1;
          text-align: center;
          font-size: 1em;
          font-weight: 500;
          border-radius: 30px;
        }
      }
    }
  }
}
