.nav-mobile {
  display: none;
  width: 100%;
  height: 60px;
  position: sticky;
  bottom: -1px;
  z-index: 1;
  @extend .flex-center;
  padding: 1rem;
  background-color: $color1;
  border-top: 1px solid $color7;
  @media (max-width: 500px) {
    display: flex;
  }

  .nav-list {
    width: 100%;
    @include flex(row, space-between, center);
    gap: 1rem;

    .nav-link {
      width: 50px;
      height: 50px;
      padding: 12px;
      border-radius: 50%;
      @extend .flex-center;
      gap: 1rem;
      font-size: 18px;
      cursor: pointer;
      transition: all 0.2s ease-out;
      position: relative;
      &:hover {
        background-color: $color3-2;
      }
      svg {
        width: 100%;
      }
      .info-notif {
        height: 18px;
        min-width: 18px;
        font-size: 11px;
        font-weight: 500;
        border-radius: 24px;
        background-color: $color3;
        @extend .flex-center;
        @include position-abs(5px, null, null, 21px, null, null);
      }
    }
  }
}
