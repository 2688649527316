.PopupLogout {
  .content {
    width: 270px;
    padding: 0.5rem 0;
    background-color: $color1;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba($color5, 0.5);
    @include flex(column, flex-start, flex-start);

    .user-card {
      width: 100%;
      border-bottom: 1px solid $color7;
      padding: 0 0.2rem 0.5rem 0.5rem;
      img,
      svg {
        width: 45px;
        height: 45px;
        object-position: center;
        object-fit: cover;
        border-radius: 50%;
      }
      svg {
        width: 20px;
      }
    }

    .add-account,
    .logout-btn {
      width: 100%;
      padding: 1rem 0.8rem;
      font-weight: 300;
      cursor: pointer;
      transition: all 0.2 ease-in-out;
      &:hover {
        background-color: $color9;
      }
    }
  }
}
